const ytConstant = {
  questionType: {
    TRUE_OR_FALSE: 0,
    SINGLE_CHOICE: 1,
    MULTIPLE_CHOICE: 2,
    FILL_IN_BLANK: 3,
    SUBJECTIVE: 4,
    CODE: 5,
    getLabel: value => {
      switch (value) {
        case 0:
          return '判断题'
        case 1:
          return '单选题'
        case 2:
          return '多选题'
        case 3:
          return '填空题'
        case 4:
          return '简答题'
        case 5:
          return '代码题'
        default:
          // 原返回值是 '未知'
          return '默认大题'
      }
    },
    getMenu: () => {
      return Object.values(ytConstant.questionType)
        .filter(item => {
          return typeof item !== 'function'
        })
        .map(item => {
          return { label: ytConstant.questionType.getLabel(item), value: item }
        })
    }
  },
  tagType: {
    QUESTION_BANK: 1, //题库
    QUESTION: 2, //题目
    POST: 3, //岗位
    USER: 4, //人员
    ORGANIZATION: 5 //企业
  },
  answerType: {
    TRUE_OR_FALSE: ['正确', '对', 'true', true, 'True', 'TRUE'],
    getLabel: data => {
      if (data.questionType === 0) {
        if (ytConstant.answerType.TRUE_OR_FALSE.includes(data.answer)) return '正确'
        else return '错误'
      } else if (data.questionType === 1 || data.questionType === 2) {
        let answer = data.options
          .map((option, index) => {
            if (option.correct) {
              return String.fromCharCode(index + 65)
            }
          })
          .filter(option => {
            return option !== undefined
          })
        return answer.toString()
      } else {
        return data.answer
      }
    },
    getValue: (type, value) => {
      if (type === 0) {
        return ytConstant.answerType.TRUE_OR_FALSE.includes(value)
      } else {
        return value
      }
    }
  },
  difficultType: {
    //题目难度
    SIMPLE_MAX: 3,
    NORMAL_MAX: 5,
    HARD_MAX: 7,
    getLabel: value => {
      if (value <= ytConstant.difficultType.SIMPLE_MAX) {
        return '简单'
      } else if (value <= ytConstant.difficultType.NORMAL_MAX) {
        return '中等'
      } else if (value <= ytConstant.difficultType.HARD_MAX) {
        return '困难'
      } else {
        return ''
      }
    },
    getValue: label => {
      switch (label) {
        case '简单':
          return ytConstant.difficultType.SIMPLE_MAX
        case '中等':
          return ytConstant.difficultType.NORMAL_MAX
        case '困难':
          return ytConstant.difficultType.HARD_MAX
        default:
          return ytConstant.difficultType.SIMPLE_MAX
      }
    },
    getMenu: () => {
      return Object.values(ytConstant.difficultType)
        .filter(item => {
          return typeof item !== 'function'
        })
        .map(item => {
          return { label: ytConstant.difficultType.getLabel(item), value: item }
        })
    }
  },
  paperDifficultyType: {
    //自动出卷难度设置
    SIMPLE: 'SIMPLE',
    MEDIUM: 'MEDIUM',
    HARD: 'HARD',
    getLabel: value => {
      if (value === ytConstant.paperDifficultyType.SIMPLE) {
        return '简单'
      } else if (value === ytConstant.paperDifficultyType.MEDIUM) {
        return '中等'
      } else if (value === ytConstant.paperDifficultyType.HARD) {
        return '困难'
      } else {
        return ''
      }
    },
    getMenu: () => {
      return Object.values(ytConstant.paperDifficultyType)
        .filter(item => {
          return typeof item !== 'function'
        })
        .map(item => {
          return { label: ytConstant.paperDifficultyType.getLabel(item), value: item }
        })
    }
  },
  paperType: {
    //自动出卷难度设置
    NORMAL: 0,
    POST_RANDOM: 4,
    POST_AUTO: 6,
    getLabel: value => {
      if (value === ytConstant.paperType.NORMAL) {
        return '手动出卷'
      } else if (value === ytConstant.paperType.POST_RANDOM) {
        return '随机出卷'
      } else if (value === ytConstant.paperType.POST_AUTO) {
        return '自动出卷'
      } else {
        return ''
      }
    }
  },
  publicKey: '04f366e49ad4290dcb09f93c24da9c2d443566d25fe37c0583ce01b85a7b164f94bda1184aabaca7f4221a11eb659a537b90107195cf75c91f2f8cc9acccbbddc2'
}

export default ytConstant
