const state = {
  examName: null,
  examPostId: null
}
const mutations = {
  setExamName(state, data) {
    state.examName = data
  },
  setExamPost(state, data) {
    state.examPostId = data
  }
}
const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
