import roleApi from '@api/role'
import permissionUtils from '../../util/permissionUtils'

const state = {
  roles: [],
  loading: false
}

// getters
const getters = {
  roles: state => {
    return state.roles
  }
}

// actions
const actions = {
  getAllRoles({ commit }) {
    //上一个请求还在，跳过
    if (state.loading) {
      return
    }

    commit('setLoading', true)

    return roleApi
      .getAllRoles()
      .then(res => {
        commit('setRoles', res.res)
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
  addRole({ dispatch }, role) {
    return roleApi.addRole(role).then(res => {
      dispatch('getAllRoles')
      return res
    })
  },
  modifyRole({ dispatch }, role) {
    return roleApi.modifyRole(role).then(res => {
      role.permissions = permissionUtils.getPermissionList(role.permission)
      dispatch('getAllRoles')
      return res
    })
  },
  deleteRole({ dispatch }, roleId) {
    return roleApi.deleteRole(roleId).then(res => {
      dispatch('getAllRoles')
      return res
    })
  }
}

// mutations
const mutations = {
  setRoles(state, roles) {
    state.roles = roles.map(role => {
      role.permissions = permissionUtils.getPermissionList(role.permission)
      return role
    })
  },
  modifyRole(state, role) {
    state.roles.splice(
      state.roles.findIndex(v => v.roleId === role.roleId),
      1,
      role
    )
  },
  deleteRole(state, roleId) {
    state.roles.splice(
      state.roles.findIndex(v => v.roleId === roleId),
      1
    )
  },
  setLoading(state, loading) {
    state.loading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
