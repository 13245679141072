import Vue from 'vue'
import Vuex from 'vuex'
import role from './modules/role'
import user from './modules/user'
import knowledge from './modules/knowledge'
import department from './modules/department'
import teacher from './modules/teacher'
import question from './modules/question'
import createPersistedState from 'vuex-persistedstate'
import createLogger from 'vuex/dist/logger'
import exam from './modules/exam'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const persistPlugin = createPersistedState({
  paths: ['question']
})

export default new Vuex.Store({
  modules: {
    role,
    user,
    department,
    teacher,
    question,
    knowledge,
    exam
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
