import config from '../config/config'
import { axios } from './index'

const sysPermissionUrl = config.baseUrl + '/user/api/v2/sys/permission'

export default {
  //获取用户可查看的菜单 终端类型，0：平台端，1：企业端，2：学员端
  getMenu() {
    return axios.get(`${sysPermissionUrl}/get/menu?endpoint=1`)
  },
  //获取终端对应的系统权限列表
  getMenuByEndpoint(payload) {
    return axios.get(`${sysPermissionUrl}/get/by-endpoint/list?endpoint=${payload}`)
  },
  //获取角色对应的系统权限
  getPermissionByRole(payload) {
    return axios.get(`${sysPermissionUrl}/get/by-role?roleId=${payload.roleId}&permissionType=${payload.permissionType}`)
  },
  //获取终端对应的系统权限菜单
  getPermissionType() {
    return axios.get(`${sysPermissionUrl}/get/by-endpoint/menu`)
  },
  //修改角色对应的系统权限
  modifyPermission(payload) {
    return axios.post(`${sysPermissionUrl}/modify/by-role`, payload)
  }
}
