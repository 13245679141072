//用uuid重命名文件防止重名
export function renameFile(file) {
  //获取后缀
  let ext = file.name.substr(file.name.lastIndexOf('.') + 1)
  return new File([file], `${generateUUID()}.${ext}`, {
    type: file.type
  })
}

//生成不重复的uuid防止oss上传出现重名文件
export function generateUUID() {
  let d = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export function download(url) {
  let a = document.createElement('a') //创建一个a标签
  a.setAttribute('href', url) //添加URL
  a.setAttribute('target', '_self') //设置target属性
  triggerEvent(a, 'click') //触发事件
}

export function downloadFlow(flow, name, format) {
  let blob = new Blob([flow])
  let fileName = `${name}.${format}`
  let a = document.createElement('a')
  a.setAttribute('href', window.URL.createObjectURL(blob)) //添加URL
  a.setAttribute('target', '_self') //设置target属性
  a.setAttribute('download', fileName) //设置文件名
  triggerEvent(a, 'click')
}

function triggerEvent(el, evName) {
  if (typeof el[evName] == 'function') {
    //如果存在这个事件就直接触发它
    el[evName]()
  } else if (el.fireEvent) {
    //如果有触发事件的方法，就调用它
    el.fireEvent('on' + evName)
  } else if (document.createEvent) {
    //否则只好手动触发
    let evt = document.createEvent('MouseEvents') //创建鼠标事件
    evt.initEvent(evName, true, true) //初始化 事件
    el.dispatchEvent(evt) //触发这个事件到此元素
  }
}
