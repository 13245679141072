//twitter 计算相对时间
function timeDifference(current, previous) {
  let msPerMinute = 60 * 1000
  let msPerHour = msPerMinute * 60
  let msPerDay = msPerHour * 24
  let msPerMonth = msPerDay * 30
  let msPerYear = msPerDay * 365

  let elapsed = current - previous

  if (elapsed < msPerMinute) {
    return '刚刚'
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + '分钟前'
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + '小时前'
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + '天前'
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + '月前'
  } else {
    return Math.round(elapsed / msPerYear) + '年前'
  }
}

function format(date, format) {
  const o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  }
  if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return format
}

export default {
  relativeTimeFilter: time => {
    //要求 time 是个 Date
    return timeDifference(new Date().getTime(), time.getTime())
  },
  formatTime: (timestamp, pattern) => {
    if (!timestamp) {
      return '----/--/--'
    }

    return format(new Date(timestamp), pattern)
  }
}
