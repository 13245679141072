import departmentApi from '@api/department'
import CourseAPI from '@api/course'
import { cloneDeep } from 'lodash'

const appendTreeNode = (node, data, expand = false, disabled) => {
  if (!node) {
    return
  }

  data.push({
    title: node.name,
    selected: false,
    checked: false,
    expand: expand,
    children: [],
    detail: node,
    disabled: disabled
  })

  node.children &&
    node.children.forEach(child => {
      appendTreeNode(child, data[data.length - 1].children, false, disabled)
    })
}

const deleteStaffsInTree = (tree, staffIds, deleteDepartment = false) => {
  const markDeletedLoop = root => {
    let deleted = deleteDepartment

    //叶子节点(包括员工节点和无员工的部门)最先判断是否需要从树中删除
    if (root.children.length === 0) {
      root.deleted = (root.isStaff && staffIds.indexOf(root.detail.userId) > -1) || (!root.isStaff && deleted)

      return root.deleted
    }

    //deleteDepartment 为 false 则非叶子节点(即部门)永远不删除，否则部门下所有子节点都删除后部门也需要删除
    root.children.forEach(child => {
      deleted = markDeletedLoop(child) && deleted
    })
    root.deleted = deleted
    return root.deleted
  }

  markDeletedLoop(tree)

  const deleteLoop = root => {
    if (root.children.length === 0) {
      return null
    }

    //批量删除逆向操作，保证下标不变
    for (let i = root.children.length - 1; i >= 0; i--) {
      if (root.children[i].deleted) {
        root.children.splice(i, 1)
      } else {
        deleteLoop(root.children[i])
      }
    }
  }

  deleteLoop(tree)
}

const state = {
  departmentTree: null,
  staffTree: null,
  staffIds: [],
  loading: false
}

// getters
const getters = {
  departmentTree: state => {
    return state.departmentTree
  },
  departmentTreeData: state => {
    return (disabled = false) => {
      let treeData = []
      appendTreeNode(state.departmentTree, treeData, true, disabled)
      return treeData
    }
  },
  departmentList: state => {
    let result = []
    const flatten = node => {
      if (!node) {
        return
      }

      result.push(node)

      node.children && node.children.forEach(child => flatten(child))
    }

    flatten(state.departmentTree)
    return result
  },
  staffTreeExclude: state => {
    return staffIds => {
      if (!state.staffTree) {
        return
      }

      const newTree = cloneDeep(state.staffTree)

      deleteStaffsInTree(newTree[0], staffIds)
      return newTree
    }
  },
  staffTreeInclude: state => {
    return staffIds => {
      if (!state.staffTree) {
        return
      }

      const newTree = cloneDeep(state.staffTree)
      deleteStaffsInTree(
        newTree[0],
        state.staffIds.filter(v => staffIds.indexOf(v) === -1),
        true
      )
      if (newTree[0].children.length === 0) {
        return []
      }

      return newTree
    }
  }
}

// actions
const actions = {
  getDepartmentTree({ commit, state }) {
    //上一个请求还在，跳过
    if (state.loading) {
      return
    }

    commit('setLoading', true)

    return departmentApi
      .getDepartmentsTree()
      .then(res => {
        commit('setDepartmentTree', res.res)
        return res
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
  /**
   * 如果提供了 CourseId, 表明需要过滤掉当
   * 前课程下的老师和已经加入课程的学生
   */
  getStaffs({ commit, state, getters }, courseId = undefined) {
    if (state.departmentTree === null) {
      return
    }

    const request = courseId
      ? CourseAPI.getAvailableStaffsByDepartments(
          getters.departmentList.map(department => department.departmentId),
          courseId
        )
      : departmentApi.getStaffsByDepartments(getters.departmentList.map(department => department.departmentId))
    return request.then(res => {
      const staffs = res.res
      const departmentTreeData = getters.departmentTreeData()
      const dfs = root => {
        const staffNodes = staffs
          .find(v => v.departmentId === root.detail.departmentId)
          .staffs.map(staff => {
            //详情中存储是否为员工节点
            return {
              title: staff.realName,
              isStaff: true,
              selected: false,
              checked: false,
              disabled: false,
              detail: staff,
              children: []
            }
          })
        root.isStaff = false
        root.children.forEach(child => dfs(child))
        root.children.unshift(...staffNodes)
      }

      dfs(departmentTreeData[0])
      commit('setStaffTree', departmentTreeData)

      //保存所有员工 id
      let staffIds = []
      staffs.forEach(v => {
        staffIds = staffIds.concat(v.staffs.map(staff => staff.userId))
      })
      commit('setStaffIds', staffIds)
    })
  }
}

// mutations
const mutations = {
  setDepartmentTree(state, departmentTree) {
    state.departmentTree = departmentTree
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setStaffTree(state, staffTree) {
    state.staffTree = staffTree
  },
  setStaffIds(state, staffIds) {
    state.staffIds = staffIds
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
