<template>
  <div class="layout">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.layout {
  background-color: white;
  padding: 20px;
  margin-top: 10px;
  box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.1);
  display: flex;
}
</style>
