import ytConstant from './constant'

//将canvas导出的dataUrl转成File类型
export function dataUrlToFile(dataUrl, filename) {
  let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([new Blob([u8arr], { type: mime })], filename, {
    type: 'image/png'
  })
}

//将html字符串转换成普通文本
export function replaceHtml(str) {
  return str
    .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
    .replace(/<[^>]+?>/g, '')
    .replace(/\s+/g, ' ')
    .replace(/ /g, ' ')
    .replace(/>/g, ' ')
    .replace(/&nbsp;/g, ' ')
}

//通过数字获取选项字母
export function getChoiceIndex(num) {
  return String.fromCharCode(num + 65)
}

//页面跳转传值
export function handleParams(key) {
  if (Object.keys(this.$route.params).length > 0) {
    sessionStorage.setItem(key, JSON.stringify(this.$route.params))
    return this.$route.params
  } else {
    return JSON.parse(sessionStorage.getItem(key))
  }
}

//深拷贝
export function deepCopy(obj) {
  let result,
    oClass = Object.prototype.toString.call(obj).slice(8, -1)
  if (oClass === 'Object') {
    result = {}
  } //判断传入的如果是对象，继续遍历
  else if (oClass === 'Array') {
    result = []
  } //判断传入的如果是数组，继续遍历
  else {
    return obj
  } //如果是基本数据类型就直接返回
  for (let i in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, i)) {
      let copy = obj[i]
      if (Object.prototype.toString.call(copy).slice(8, -1) === 'Object') {
        //递归方法 ，如果对象继续变量obj[i],下一级还是对象，就obj[i][i]
        result[i] = deepCopy(copy)
      } else if (Object.prototype.toString.call(copy).slice(8, -1) === 'Array') {
        //递归方法 ，如果对象继续数组obj[i],下一级还是数组，就obj[i][i]
        result[i] = deepCopy(copy)
      } else {
        //基本数据类型则赋值给属性
        result[i] = copy
      }
    }
  }
  return result
}

//小数四舍五入
export function roundFloat(data, length) {
  //计算出保留的位数
  let resLength = Math.pow(10, length)
  if (data !== null && data !== undefined && data !== '') {
    return Math.round(data * 100 * resLength) / resLength
  } else {
    return 0
  }
}

//浮点数乘法, 解决精度丢失
export function floatMultiply(arg1, arg2) {
  arg1 = Number(arg1)
  arg2 = Number(arg2)
  if ((!arg1 && arg1 !== 0) || (!arg2 && arg2 !== 0)) {
    return null
  }
  arg1 = toNonExponential(arg1)
  arg2 = toNonExponential(arg2)
  let n1, n2
  let r1, r2 // 小数位数
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  n1 = Number(arg1.toString().replace('.', ''))
  n2 = Number(arg2.toString().replace('.', ''))
  return (n1 * n2) / Math.pow(10, r1 + r2)
}

//浮点数加法, 解决精度丢失
export function floatAdd(n, m) {
  const { F, S, T, l1, l2 } = getInteger(n, m)
  return (F[0] * T + (F[1] * T) / Math.pow(10, l1) + S[0] * T + (S[1] * T) / Math.pow(10, l2)) / T
}

function getInteger(n, m) {
  n = typeof n === 'string' ? n : numToString(n)
  m = typeof m === 'string' ? m : numToString(m)
  let F = n.indexOf('.') !== -1 ? handleNum(n) : [n, 0, 0],
    S = m.indexOf('.') !== -1 ? handleNum(m) : [m, 0, 0],
    l1 = F[2],
    l2 = S[2],
    L = Math.max(l1, l2),
    T = Math.pow(10, L)
  return {
    F,
    S,
    T,
    l1,
    l2
  }
}
function numToString(tempArray) {
  if (typeof tempArray === 'number') {
    return tempArray.toString()
  }
  return '0'
}
function handleNum(n) {
  n = n.toString()
  let temp = n.split('.')
  temp.push(temp[1].length)
  return temp
}

//登录明文加密
export function doEncrypt(msgString) {
  let msg = msgString
  if (typeof msgString !== 'string') {
    msg = JSON.stringify(msgString)
  }
  let sm2 = require('sm-crypto').sm2
  let cipherMode = 1 // 1 - C1C3C2，0 - C1C2C3，默认为1
  let publicKey = ytConstant.publicKey // 公钥
  // 加密结果
  return sm2.doEncrypt(msg, publicKey, cipherMode)
}
/**
 * 将科学计数法的数字转为字符串
 * 说明：运算精度丢失方法中处理数字的时候，如果出现科学计数法，就会导致结果出错
 * @param  num
 */
function toNonExponential(num) {
  if (num == null) {
    return num
  }
  if (typeof num == 'number') {
    let m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/)
    return num.toFixed(Math.max(0, (m[1] || '').length - m[2]))
  } else {
    return num
  }
}

//判断字符串是否全为空格
export function regSpace(value) {
  let reg = /^(?=.*\S).+$/gm
  return !reg.test(value)
}

//手动调整侧边框宽度
export function dragControllerDiv() {
  //获取侧边栏和可调整元素
  const flexible = document.querySelector('.yt-content-tree')
    ? document.querySelector('.yt-content-tree')
    : document.querySelector('.question-bank-content')
  const bar = document.querySelector('.yt-resize')
  let startX, startWidth
  //给可调节元素添加mousedown监听事件
  bar.addEventListener('mousedown', start)
  function start(e) {
    //记录初始鼠标的横坐标和侧边栏的宽度
    startX = e.clientX
    startWidth = parseInt(getComputedStyle(flexible, null).width)
    //监听mousemove和mouseup
    document.documentElement.addEventListener('mousemove', move)
    document.documentElement.addEventListener('mouseup', end)
  }
  function move(e) {
    //拖拽时禁止选中文字
    getSelection ? getSelection().removeAllRanges() : document.selection.empty()
    //给左侧边栏赋值
    flexible.style.width = `${startWidth + e.clientX - startX}px`
  }
  function end(e) {
    document.documentElement.removeEventListener('mousedown', start)
    document.documentElement.removeEventListener('mousemove', move)
    document.documentElement.removeEventListener('mouseup', end)
  }
}

export default {
  formatDateAndTime(date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
  },

  formatTime(date) {
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    return [hour, minute, second].map(formatNumber).join(':')
  },

  formatDate(date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    return [year, month, day].map(formatNumber).join('/')
  },

  formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
  },

  throttle(fn, wait) {
    let inThrottle, lastFn, lastTime
    return function() {
      const context = this,
        args = arguments
      if (!inThrottle) {
        fn.apply(context, args)
        lastTime = Date.now()
        inThrottle = true
      } else {
        clearTimeout(lastFn)
        lastFn = setTimeout(function() {
          if (Date.now() - lastTime >= wait) {
            fn.apply(context, args)
            lastTime = Date.now()
          }
        }, Math.max(wait - (Date.now() - lastTime), 0))
      }
    }
  },
  //导出
  exportExamPeoInfo(res, name, format) {
    //
    const blob = new Blob([res])
    const fileName = format === '.zip' ? `${name}${format}` : `${name}.xls`
    const elink = document.createElement('a')
    elink.style.display = 'none'
    elink.download = fileName
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href)
    document.body.removeChild(elink)
  },
  dateTime(date) {
    const year = date.getFullYear()
    let month = date.getMonth() + 1
    month = month > 9 ? month : `0${month}`
    let day = date.getDate()
    day = day > 9 ? day : `0${day}`
    let hour = date.getHours()
    hour = hour > 9 ? hour : `0${hour}`
    let minute = date.getMinutes()
    minute = minute > 9 ? minute : `0${minute}`
    let second = date.getSeconds()
    second = second > 9 ? second : `0${second}`
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
  },
  cascSearchById(a, data, data1, id) {
    // 级联回显
    // data结构需要时value,children;
    // id number类型
    data.some(item => {
      if (item.value === id) {
        a.push(item.value)
        if (item.parentId !== 0) {
          this.cascSearchById(a, data1, data1, item.parentId)
        }
        return true
      }
      if (item.children) {
        this.cascSearchById(a, item.children, data1, id)
      }
    })
    return a
  },
  /**
   * 全局通用message
   */
  overSituationMsg(msg, type, element) {
    let elM = document.getElementsByClassName('el-message')
    if (elM.length === 0) {
      // 防连续点击  message霸屏
      return element.$message({ message: msg, type: type })
    }
  },
  //格式化考试用时
  examFormatTime(time) {
    let theTime = parseInt(time * 60) // 需要转换的时间秒
    let theTime1 = 0 // 分
    let theTime2 = 0 // 小时
    let theTime3 = 0 // 天
    if (theTime > 60) {
      theTime1 = parseInt(theTime / 60)
      theTime = parseInt(theTime % 60)
      if (theTime1 > 60) {
        theTime2 = parseInt(theTime1 / 60)
        theTime1 = parseInt(theTime1 % 60)
        if (theTime2 > 24) {
          //大于24小时
          theTime3 = parseInt(theTime2 / 24)
          theTime2 = parseInt(theTime2 % 24)
        }
      }
    }
    let result = ''
    if (theTime > 0) {
      result = '' + parseInt(theTime) + '秒'
    }
    if (theTime1 > 0) {
      result = '' + parseInt(theTime1) + '分钟' + result
    }
    if (theTime2 > 0) {
      result = '' + parseInt(theTime2) + '小时' + result
    }
    if (theTime3 > 0) {
      result = '' + parseInt(theTime3) + '天' + result
    }
    return result
  }
}
