export const questionType = {
  0: {
    value: 0,
    label: '单选题'
  },
  1: {
    value: 1,
    label: '多选题'
  },
  2: {
    value: 2,
    label: '判断题'
  },
  3: {
    value: 3,
    label: '填空题'
  },
  4: {
    value: 4,
    label: '代码题'
  },
  5: {
    value: 5,
    label: '简答题'
  }
}

export const getQuestionTypeByValue = value => {
  const typeList = Object.keys(questionType)
  for (const status of typeList) {
    if (questionType[status].value === value) {
      return questionType[status]
    }
  }
  return questionType.UNKNOWN
}

export const questionTypeNew = function(type) {
  switch (type) {
    case 0:
      return '判断题'
    case 1:
      return '单选题'
    case 2:
      return '多选题'
    case 3:
      return '填空题'
    case 4:
      return '简答题'
    case 5:
      return '代码题'
  }
}
