<template>
  <div class="code-editor-layout">
    <el-tabs v-model="acName" type="card" @tab-click="handleClick">
      <el-tab-pane name="java" label="java" />
      <el-tab-pane name="JavaScript" label="JavaScript" />
      <el-tab-pane name="c" label="c" />
      <el-tab-pane name="c++" label="c++" />
      <el-tab-pane name="python" label="python" />
      <el-tab-pane name="bash" label="bash" />
    </el-tabs>
    <div class="wrapper">
      <codemirror ref="cmRef" v-model="code" :options="cmOptions" @ready="onCmReady" @input="handleChange" />
    </div>
  </div>
</template>

<script>
import question from '../../api/question'
import { codemirror } from 'vue-codemirror'
// import CodeAll from 'vue-codemirror'
// import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/base16-dark.css'
// import 'codemirror/mode/clike/clike.js'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/clike/clike.js'
// import 'codemirror/addon/hint/show-hint.js'
// import 'codemirror/addon/hint/show-hint.css'
// import 'codemirror/addon/hint/anyword-hint'

import 'codemirror/theme/base16-dark.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/hint/show-hint.css'
// import 'codemirror/lib/codemirror'
// import 'codemirror/mode/sql/sql'
import 'codemirror/addon/hint/show-hint'
// import 'codemirror/addon/search/searchcursor'
// import 'codemirror/addon/search/search'
// import 'codemirror/addon/display/placeholder'
// import 'codemirror/addon/hint/sql-hint'
import 'codemirror/addon/hint/anyword-hint'

export default {
  name: 'CodeEditor',
  props: {
    isAdd: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array
    },
    initialValue: {
      type: Array
    }
  },
  components: {
    codemirror
  },
  created() {
    // this.getDefaultTemplate()
  },
  mounted() {
    const cm = this.$refs['cmRef']
    this.$emit('input', this.templateList)
  },
  watch: {
    isAdd: {
      handler(val) {
        if (val) {
          this.getDefaultTemplate()
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      code: '',
      acName: 'java',
      templateList: [],
      IsAddData: [],
      // cmOptions: {
      //   // codemirror options
      //   matchBrackets: true,
      //   extraKeys: { '.': 'autocomplete' },
      //   tabSize: 4,
      //   mode: 'text/x-java',
      //   theme: 'base16-dark',
      //   lineNumbers: true,
      //   line: true
      // }
      cmOptions: {
        lineNumbers: true,
        indentUnit: 4,
        // autofocus: true,
        styleActiveLine: true,
        matchBrackets: true,
        mode: 'text/x-java',
        // lineWrapping: true,
        // theme: 'base16-dark',
        extraKeys: {
          Ctrl: 'autocomplete'
        }
        // foldGutter: true
      }
    }
  },
  methods: {
    getData(data) {
      if (data.length === 0) return this.getDefaultTemplate()
      if (!this.isAdd) {
        this.templateList = data
        this.getTemplate(data, this.acName)
      }
    },
    getDefaultTemplate() {
      question.getDefaultTemplate().then(res => {
        this.templateList = res.res
        if (this.templateList.length > 0) {
          this.getTemplate(this.templateList, this.acName)
        }
      })
    },
    getTemplate(data, value) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].codeType === value) {
          this.$nextTick(() => {
            this.isAdd ? (this.code = data[i].defaultTemplate || '') : (this.code = data[i].template || data[i].defaultTemplate || '')
          })
        }
      }
    },
    handleClick(e) {
      this.acName = e.name
      this.getTemplate(this.templateList, this.acName)
    },
    onCmReady(cm) {
      cm.on('keypress', e => {
        // cm.showHint()
      })
    },
    handleChange(newValue) {
      let d = this.templateList
      d.map(item => {
        if (item.codeType === this.acName) {
          item.template = newValue
        } else {
          item.template = item.defaultTemplate || item.template
        }
      })
      this.$emit('input', d)
    },
    setContent(val) {
      this.code = val
    },
    clearEditor() {
      this.code =
        'import java.util.Scanner;\n' +
        'import java.util.*;\n' +
        'public class Main {\n' +
        '  public static void main(String args[]) {\n' +
        '      Scanner scanner = newPaper Scanner(System.in);\n' +
        '      // Your Code here...\n' +
        '     System.out.println("Your answer here");\n' +
        '  }\n' +
        '}'
    }
  }
}
</script>

<style scoped>
.code-editor-layout {
  height: 100%;
}
.wrapper {
  height: 100%;
  background: #151515;
}
</style>
