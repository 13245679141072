import config from '../config/config'
import { axios } from './index'
import qs from 'querystring'

const baseUrl = config.baseUrl
const authHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Authorization: 'Basic WXVhblR1Oll1YW5UdQ==',
  'Self-Origin': 'enter.yuantusoft.com'
}

export default {
  getShow() {
    return axios.post(config.codeUrl + '/user/api/v1/org/show')
  },
  getAccessToken(username, password) {
    return axios.post(
      baseUrl + '/auth/oauth/token?grant_type=password',
      qs.stringify({
        username: username,
        password: password,
        role: 1
      }),
      {
        headers: authHeaders
      }
    )
  },
  getCodes(phone) {
    return axios.post(config.codeUrl + '/v1/org/sms/code/add?phone=' + phone)
  },

  getNewPasswordCode(phone) {
    return axios.post(config.codeUrl + '/user/api/v1/user/send/pwd/code/auth?account=' + phone)
  },

  submitNewPwd(newPasswordObj) {
    return axios.post(config.codeUrl + '/user/api/v1/user/change/pwd/auth', newPasswordObj)
  },

  companyRegister(data) {
    return axios.post(config.codeUrl + '/v1/org/addApply', data)
  },

  refreshToken(refreshToken) {
    return axios.post(
      baseUrl + '/auth/oauth/token?grant_type=refresh_token',
      qs.stringify({
        refresh_token: refreshToken
      }),
      {
        headers: authHeaders
      }
    )
  },

  logout() {
    return axios.post(baseUrl + '/auth/logout')
  },

  userDetail() {
    return axios.get(baseUrl + '/user/api/v1/user/detail')
  },

  getManagerList() {
    return axios.get(baseUrl + '/user/api/v1/administrator/manager/course')
  },

  /**
   * 分页获取用户消息
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  getPersonalMessage(payload) {
    return axios.post(baseUrl + '/user/api/v1/user/message', payload)
  },
  /**
   * 把消息标记为已读
   * @param id
   * @returns {AxiosPromise<any>}
   */
  readMessage(id) {
    return axios.post(`${baseUrl}/user/api/v1/user/message/read?messageId=${id}`)
  },
  /**
   * 获取用户前五条未读消息 和 总消息数
   * @param data
   * @returns {AxiosPromise<any>}
   */
  getPersonalUnreadMessagePreview(userId) {
    const payload = {
      type: 0,
      userId
    }
    return axios.post(baseUrl + '/user/api/v1/user/message/unread?page=0&size=5', payload)
  },

  /**
   * 获取组织下员工总数的统计数据
   * @returns {AxiosPromise<any>}
   */
  getUserCountStatistic() {
    return axios.get(`${baseUrl}/user/api/v1/statistic/staff/sum`)
  },
  /*
   * 导入员工下载模板
   * */
  downLoadstaff() {
    return `${baseUrl}/user/api/v1/files/download/excel/auth`
  }
}
