<template>
  <div class="top-bar">
    <div
      class="logo"
      :style="{
        width: isSpread ? 'var(--top-log-width)' : '54px',
        paddingLeft: isSpread ? '28px' : '0',
        justifyContent: isSpread ? 'flex-start' : 'center'
      }"
    >
      <img v-if="!isSpread" :src="logoUrl" alt="logo" />
      <div v-else>{{ name ? name : organizationName }}</div>
    </div>
    <!--    <div style="display: flex;align-items: center" v-if="isShns">-->
    <!--      <img class="logo" src="../assets/shnsLogo@2x.png" alt="logo">-->
    <!--      <div style="font-size: 20px;margin-left: 5px">金融科技数字化人才管理平台</div>-->
    <!--    </div>-->
    <!--    <img class="logo" src="../assets/logo/nshLogo.png" alt="logo" v-else>-->
    <UserBar />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import image from '../assets/defaultAvatar.svg'
import userApi from '@api/user'
import UserBar from './UserBar'

export default {
  props: ['isSpread', 'organizationName'],
  components: {
    UserBar
  },
  methods: {
    logout() {
      userApi.logout().then(() => {
        this.$router.push('/login')
      })
    },
    getShow() {
      userApi.getShow().then(res => {
        if (res.code === 0) {
          this.name = res.res
        }
      })
    }
  },
  data() {
    return {
      isShns: false,
      name: ''
    }
  },
  created() {
    this.getShow()
    this.isShns = window.g.isShns
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/info'
    }),
    logoUrl() {
      return this.userInfo.organizationLogoUrl !== null ? this.userInfo.organizationLogoUrl : require('@assets/logo/logo.png')
    },
    image: function() {
      return image
    }
  }
}
</script>
<style lang="less" scoped>
@import '../theme/variables';

.top-bar {
  color: #ffffff;
  font-size: @small;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  box-sizing: border-box;
  height: 100%;

  /*@media only screen and (min-device-pixel-ratio: 2) {*/
  /*  img {*/
  /*    content: url('../assets/logo@2x.png');*/
  /*  }*/
  /*}*/
}

.logo {
  height: calc(100vw * var(--top-height) / 1440);
  max-height: calc(var(--top-height) * 1px);
  background-color: var(--top-logo);
  color: var(--top-logo-color);
  font-size: @large;
  display: flex;
  align-items: center;
  padding-left: 28px;
  transition: width @default-transition;
  div {
    text-align: left;
    //margin-left: 28px;
  }
  img {
    width: 35px;
    height: 35px;
  }
}
.left {
  alignment: center;
  height: 50px;
  .text {
    float: right;
    margin-top: 5px;
  }
}
</style>
