<template>
  <div class="JYSimpleButton">
    <JYIcon v-if="icon" :href="icon" @click="handleClick" />
    <slot />
  </div>
</template>

<script>
import JYIcon from '@components/common/JYIcon'
export default {
  props: ['icon'],
  methods: {
    handleClick() {
      this.$emit('click')
    }
  },
  components: {
    JYIcon
  }
}
</script>

<style lang="less" scoped>
@import '../../theme/variables.less';
.JYSimpleButton {
  display: inline;
  cursor: pointer;
  padding: 3px;
  &:hover {
    color: #88a1cb;
  }
}
</style>
