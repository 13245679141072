/**
 * 统一异步请求处理
 */

import axiosLibrary from 'axios'
/**
 * 使用的 axios 实例自己生成, 避免污染全局 axios
 * 导致其他依赖 axios 的库出错
 * 具体发送 api 的地方要 import { axios } from './index', 而不能直接 import axios from 'axios'
 * 目前强依赖 axios 全局实例并且不允许劫持 response 的库有:
 * 1. 腾讯云上传SDK 'vod-js-sdk-v6'
 */
const axios = axiosLibrary.create({
  baseURL: window.g.apiUrl,
  headers: {
    'Self-Origin': 'enter.yuantusoft.com'
  }
})
import { Message } from 'element-ui'
import responseCode from './response.code'
import router from '../router'
/**
 * 设置公共授权头部
 */
const setCommonHeader = () => {
  let auth = localStorage.getItem('auth')
  if (auth) {
    axios.defaults.headers.common = {
      Authorization: 'Bearer ' + JSON.parse(auth).access_token
    }
  }
}
const passCodes = [7223, 9019]
const unCancelUrl = ['user/api/v1/notice']
//不提示报错信息的错误码
function isTokenUrl(url) {
  return url.indexOf('auth/oauth/token') > -1
}

setCommonHeader()
window._axiosPromiseArr = []
axios.interceptors.request.use(
  config => {
    //存储请求方便在路由跳转后停止挂起的请求
    let isUnCancel = unCancelUrl.some(url => {
      return config.url.includes(url)
    })
    if (!isUnCancel) {
      config.cancelToken = new axiosLibrary.CancelToken(function(cancel) {
        window._axiosPromiseArr.push({ cancel })
      })
    }
    return config
  },
  err => {
    Message.error('请求超时!')
    return Promise.reject(err)
  }
)
axios.interceptors.response.use(
  data => {
    if (isTokenUrl(data.config.url)) {
      return data.data
    }

    if (data.data.code && data.data.code !== responseCode.OK) {
      if (!passCodes.includes(data.data.code)) {
        Message.error(data.data.res)
      }
    }
    return data.data
  },
  err => {
    if (err.response === undefined) {
      return Promise.reject(err)
    }
    if (!err.response) {
      Message.error('未知错误！')
      return Promise.reject(err)
    }
    // 不会取消请求的接口亦不进入拦截器
    let isUnCancel = unCancelUrl.some(url => {
      return err.response.config.url.includes(url)
    })
    if (isUnCancel) {
      return Promise.reject(err)
    }
    switch (err.response.status) {
      case 400:
        Message.error('暂未登录，请先登录')
        if (window._axiosPromiseArr === undefined) {
          window._axiosPromiseArr = []
        }
        window._axiosPromiseArr.forEach((item, index) => {
          item.cancel('请求取消')
          delete window._axiosPromiseArr[index]
        })
        router.push(`/login`)
        break
      case 401:
        let config = err.response.config
        if (isTokenUrl(config.url)) {
          return Promise.resolve(err.response.data)
        }
        if (config.url.indexOf('/auth/logout') === -1) {
          Message.error('您的账号登录过期或在别的地点登录！')
          if (window._axiosPromiseArr === undefined) {
            window._axiosPromiseArr = []
          }
          window._axiosPromiseArr.forEach((item, index) => {
            item.cancel('请求取消')
            delete window._axiosPromiseArr[index]
          })
        }
        // 重新登录
        window.localStorage.setItem('login-success-to-url', router.currentRoute.fullPath)
        router.push(`/login`)
        localStorage.removeItem('yt-saas-user-info')
        localStorage.removeItem('yt-saas-menu-info')
        break
      case 403:
        Message.error('权限不足，请联系管理员！')
        break
      case 404:
        Message.error('资源未找到！')
        break
      case 500:
        Message.error('服务维护中, 请稍等!')
        break
      default:
        if (err.response.data.res) {
          Message.error(err.response.data.res)
        } else {
          Message.error('服务维护中, 请稍等!')
        }
    }
    return Promise.reject(err)
  }
)

export const saveAuthInfo = info => {
  localStorage.setItem('auth', info && JSON.stringify(info))
  setCommonHeader()
}

export { axios }
