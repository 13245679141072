import userApi from '@api/user'
import noticeApi from '@api/notice'
import Vue from 'vue'

const state = {
  info: {},
  messages: [],
  isHasUnRead: false,
  totalUnread: 0,
  maxPage: 0
}

// getters
const getters = {
  info: state => {
    return state.info
  }
}

// actions
const actions = {
  getUserInfo({ commit }) {
    return userApi.userDetail().then(res => {
      commit('setUserInfo', res.res)
    })
  },
  /**
   * 获取用户的前五条未读消息和总消息数
   * @param commit
   * @returns {Promise<void>}
   */
  async getUserMessage({ state, commit }) {
    const { res } = await userApi.getPersonalUnreadMessagePreview(state.info.userId)
    commit('setUserMessages', res)
  },
  getUnReadInfo({ state, commit }) {
    // 获取是否含有未读消息
    noticeApi.hasUnReadMessages().then(res => {
      if (res.code === 0) {
        commit('ifUnread', res.res)
      }
    })
  },
  getAllMessages({ dispatch, state, commit }, page) {
    // 获取消息列表
    // page 分页数据
    // size  默认 5/页
    noticeApi.getAllMsgs(page, 5).then(res => {
      if (res.code === 0) {
        commit('setUserMessages', res.res)
        dispatch('getUnReadInfo')
      }
    })
  },
  setUnReadMessages({ dispatch, state, commit }, payload) {
    // 改变未读消息的状态
    noticeApi.setUnReadMsg([payload.noticeReceiveId]).then(res => {
      if (res.res && res.code === 0) {
        dispatch('getUnReadInfo', res.res)
        state.messages.forEach((m, index) => {
          if (payload.rowIndex === index) {
            m.status = 0
          }
        })
      }
    })
  }
}

// mutations
const mutations = {
  setUserInfo(state, info) {
    state.info = info
  },
  setUserMessages(state, message) {
    state.messages = JSON.parse(JSON.stringify(state.messages.concat(message.data)))
    state.totalUnread = message.total
    if (message.total % 5 === 0) {
      state.maxPage = message.total / 5
    } else {
      state.maxPage = Math.round(message.total / 5) + 1
    }
  },
  ifUnread(state, res) {
    state.isHasUnRead = res
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
