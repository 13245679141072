<template>
  <div class="user-container">
    <el-dropdown :hide-on-click="false" class="dropdown_s" trigger="click">
      <span>
        <el-badge :is-dot="isHasUnRead" class="item">
          <svg aria-hidden="true">
            <use xlink:href="#icon-message" />
          </svg>
        </el-badge>
      </span>
      <el-dropdown-menu slot="dropdown" v-infinite-scroll="getList">
        <div
          v-if="msgLength === 0"
          style="display: flex;
          justify-content: center;
          font-size: 14px;
          line-height: 358px"
        >
          <span>{{ message }}</span>
        </div>
        <el-dropdown-item v-for="(m, mIndex) in messageList" :key="mIndex">
          <div @mouseenter="setUnReadData(m, mIndex)" class="yt-flex-layout content_s">
            <div class="left_s yt-flex-layout">
              <YTIcon href="#icon-message" />
              <span>{{ m.content }}</span>
            </div>
            <span class="right_s" v-if="!m.status">{{ $beautifyTime(m.sendTime) }}</span>
            <span class="right_s-unread" v-else />
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="user">
      <img
        class="avatar"
        style="margin-right: 0"
        :src="userInfo && userInfo.avatar !== '' && userInfo.avatar !== null ? userInfo.avatar : image"
        alt="头像"
      />
      <!--      <div class="name">{{userInfo.realName}}</div>-->
      <div class="opt-container">
        <div class="opt" @click="toUserInfo">账号安全</div>
        <div class="opt" @click="logout">退出</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import image from '../assets/defaultAvatar.svg'
import userApi from '@api/user'
import DropdownPanel from '@components/common/DropdownPanel'
import YTIcon from '@components/common/YTIcon'
import noticeApi from '@api/notice'

export default {
  components: {
    YTIcon,
    DropdownPanel
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/info'
    }),
    ...mapState({
      messageList: state => state.user.messages,
      total: state => state.user.totalUnread,
      maxPage: state => state.user.maxPage,
      isHasUnRead: state => state.user.isHasUnRead
    }),
    image: function() {
      return image
    }
  },
  data() {
    return {
      msgLength: [],
      scrollPage: 0,
      // 滚动加载分页
      // messageList: []
      message: '暂无消息'
    }
  },
  mounted() {
    this.getList()
    this.msgLength = this.messageList.length
  },
  methods: {
    ...mapActions('user', ['getAllMessages', 'setUnReadMessages']),
    setUnReadData(row, index) {
      // 改未读为已读
      //noticeReceiveId
      if (row.status) {
        let payload = {
          noticeReceiveId: row.noticeReceiveId,
          rowIndex: index
        }
        this.setUnReadMessages(payload)
      }
    },
    getList() {
      if (this.scrollPage <= this.maxPage) {
        this.getAllMessages(this.scrollPage)
        this.scrollPage += 1
      }
    },
    logout() {
      userApi.logout().then(() => {
        this.$router.push('/login')
        localStorage.removeItem('auth')
        localStorage.removeItem('yt-saas-user-info')
        localStorage.removeItem('yt-saas-menu-info')
      })
    },
    toUserInfo() {
      this.$router.push('/manage/userInfo')
    },
    toMessageBox() {
      this.$refs['msg-list'].toggle()
      this.$router.push('/personal/message')
    }
  }
}
</script>
<style lang="less" scoped>
@import '../theme/variables';

.user-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  .dropdown_s {
    margin-right: 10px;
    cursor: pointer;
  }
}

.noti {
  display: flex;
  position: relative;
  margin-right: 30px;
}

svg {
  width: 16px;
  height: 18px;
  /*fill: white;*/
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: relative;
  height: 100%;

  box-sizing: content-box;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 20px;

  cursor: pointer;
}

.user div.name {
  line-height: 30px;
}

.opt-container {
  position: absolute;
  top: 100%;
  right: 0;
}

.opt {
  width: 11em;
  height: 0;
  text-align: left;
  padding-left: 2em;
  background-color: var(--top-option);
  color: var(--top-option-color);
  line-height: 50px;
  overflow: hidden;
  transition: all @default-transition;

  &:hover {
    background-color: var(--top-avatar-hover);
  }
}

.user:hover {
  background: var(--top-avatar-hover);
}

.user:hover .opt {
  height: 50px;
}

.user:hover .opt:first-child {
  border-bottom: solid 1px #121a22;
}

img.avatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 1em;
  background-color: white;
}

.message-list {
  text-align: start;
  width: 300px;
  background: white;
  color: #282828;
  box-shadow: 0 1px 3px 0 rgba(175, 179, 181, 0.5);
  font-size: 12px;
  user-select: none;
  & > .header {
    height: 50px;
    background: #e3e3e3;
    padding: 0 15px;
    display: flex;
    align-items: center;
  }
  & > .footer {
    text-align: center;
    height: 40px;
    display: flex;
    padding: 0 15px;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
      background: #f7f7f7;
    }
  }
}
.message-item {
  width: auto;
  height: 36px;
  border-bottom: solid 1px #e3e3e3;
  padding: 15px 10px;
  box-sizing: initial;
  & > .title {
  }
  & > .date {
    color: #888;
    margin-top: 5px;
  }
  &:hover {
    cursor: pointer;
    background: #f7f7f7;
  }
}

/*::v-deep {*/
.el-dropdown-menu {
  padding: 10px;
  height: 380px;
  width: 480px;
  overflow-y: auto;
  .el-dropdown-menu__item {
    height: 75px;
  }
  .content_s {
    height: 100%;
    justify-content: space-between;
    align-items: center;
    .font(14px, Regular, #000);
    .left_s {
      align-items: center;
      width: 85%;
      svg {
        .wh(30px, 30px);
      }
      span {
        margin-left: 20px;
        white-space: normal;
      }
    }
    .right_s {
      flex: 1;
      text-align: right;
    }
    .right_s-unread {
      //#438bff
      border-radius: 50%;
      padding: 5px;
      background: #438bff;
    }
  }
}
/*}*/
</style>
