import knowledgeApi from '@api/knowledge'

const state = {
  rootKnowledgeList: null,
  allKnowledgeList: null
}

// getters
const getters = {
  rootKnowledgeList: state => {
    return state.rootKnowledgeList
  }
}

// actions
const actions = {
  getRootKnowledgeList({ commit }) {
    return knowledgeApi.getRootKnowledgeList().then(res => {
      commit('setRootKnowledgeList', res.res)
      return res
    })
  },
  async getAllKnowledgeList({ commit, state }) {
    let allRootKnowledge
    if (state.rootKnowledgeList && state.rootKnowledgeList.length > 0) {
      allRootKnowledge = [...state.rootKnowledgeList]
    } else {
      const { res } = await knowledgeApi.getRootKnowledgeList()
      allRootKnowledge = res
    }
    // TODO 查询子节点没有 batch 接口，一次发 8-10 个 root 查询有性能问题
    // const allRootKnowledgeIds = allRootKnowledge.map(knowledge => knowledge.id)
    commit('setAllKnowledgeList', allRootKnowledge)
  }
}

// mutations
const mutations = {
  /**
   * 设置根知识点
   * @param state
   * @param rootKnowledgeList
   */
  setRootKnowledgeList(state, rootKnowledgeList) {
    state.rootKnowledgeList = rootKnowledgeList
  },
  /**
   * 设置所有根知识点和展开的知识点
   * @param state
   * @param allKnowLedges
   */
  setAllKnowledgeList(state, allKnowLedges) {
    state.allKnowledgeList = allKnowLedges
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
