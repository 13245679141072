let baseUrl = window.g.apiUrl

// baseUrl === 'http://10.20.179.45:8300' ||
// baseUrl === 'http://10.20.157.217:8300' ||
// baseUrl === 'http://10.20.83.199:8300' ||
// baseUrl === 'https://app01.ittms.dev.srcb:8300' ||
// baseUrl === 'https://ittraining.gl.srcb:8300'
let downMB = 'https://10.20.83.202:9000'
let questionUrl = baseUrl
let codeUrl = baseUrl
let totalUrl = 'https://47.99.50.105:8882'

// 开发环境使用localhost，代理为远程服务器
if (process.env.NODE_ENV === 'development') {
  // baseUrl = '/api'
  questionUrl = '' // 搜索题目用的接口
  codeUrl = ''
  totalUrl = '/api'
}
/**
 * baseUrl 构建时覆盖
 */
if (process.env.VUE_APP_OVERWRITE_BASE_URL) {
  baseUrl = process.env.VUE_APP_OVERWRITE_BASE_URL
  questionUrl = process.env.VUE_APP_OVERWRITE_BASE_URL
  totalUrl = process.env.VUE_APP_OVERWRITE_BASE_URL
  codeUrl = process.env.VUE_APP_OVERWRITE_BASE_URL
}

export default {
  baseUrl,
  questionUrl,
  codeUrl,
  totalUrl,
  downMB
}
