const PERMISSIONS = [
  {
    index: 1,
    permission: '课程管理',
    icon: 'icon-kecheng',
    iconClass: 'course-color'
  },
  {
    index: 2,
    permission: '题库管理',
    icon: 'icon-tiku',
    iconClass: 'question-color'
  },
  {
    index: 3,
    permission: '组织架构管理',
    icon: 'icon-xitongguanli',
    iconClass: 'system-color'
  },
  {
    index: 4,
    permission: '考试管理',
    icon: 'icon-kaoshi',
    iconClass: 'exam-color'
  }
]

export default {
  getPermissionList(intPermission) {
    return PERMISSIONS.filter(permission => {
      return intPermission & (1 << permission.index)
    }).map(permission => permission.index)
  },
  getPermissionIconList(permissionList) {
    //每个permission只是个index
    return permissionList.map(permission => {
      return PERMISSIONS.find(v => v.index === permission)
    })
  },
  generatePermission(permissionList) {
    return permissionList.reduce((last, current) => {
      return last | (1 << current)
    }, 0)
  },
  PERMISSIONS
}
