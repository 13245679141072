<template>
  <div class="progress-bar">
    <div class="progress-inner" :style="currentPercentageStyle"></div>
    <div class="percent-text">{{ this.percent }}</div>
  </div>
</template>

<script>
export default {
  name: 'JYProgress',
  props: {
    percent: {
      type: Number,
      default: 0
    }
  },
  computed: {
    currentPercentageStyle() {
      return `width: ${this.percent}%;`
    }
  }
}
</script>

<style lang="less" scoped>
.progress-bar {
  height: 10px;
  width: 150px;
  margin-bottom: 5px;
  position: relative;
  background: #ebeef5;
  .progress-inner {
    position: absolute;
    height: 10px;
    background: #6c91da;
    transition: 0.3s;
  }
  .percent-text {
    position: absolute;
    height: 10px;
    top: 0;
    left: 50%;
    display: flex;
    align-items: center;
  }
}
</style>
